import axios from 'axios'
import _ from 'lodash'

export default {
  namespaced: true,
  state: {
    pagination: {},
    houses: [],
    house: {},
    page: 1,
    // SELECTION
    selectionActive: false,
    // FILTER PARAMS
    searchParams: {},
    filterParamsLocal: {},
    filterParamsSend: {},
    filterParamsActive: 0,
    filterParamsOriginal: {
      districts: [],
      settlements: [],
      streets: [],
      created: null,
      priceFrom: null,
      priceTill: null,
      material: [],
      buildingType: [],
      floorFrom: null,
      floorTill: null,
      floor: [],
      floors: [],
      noOfRooms: [],
      autonomousHeatType: null,
      squareTotalTill: null,
      squareTotalFrom: null,
      squareLivingTill: null,
      squareLivingFrom: null,
      squareKitchenFrom: null,
      squareKitchenTill: null,
      squareLandFrom: null,
      squareLandTill: null,
      renovated: null,
      agent: [],
      garage: null,
      archivedAt: null,
      archivedTill: null,
      archivedReason: [],
      soldBy: [],
      relation: null,
      source: [],
      contactRoles: []
    }
  },
  mutations: {
    set(state, houses) {
      state.houses = _.unionBy(state.houses, houses, 'id')
    },
    setHouse(state, house) {
      state.house = house
    },
    updateHouse(state, house) {
      const foundHouse = state.houses.find(x => x.id === house.id)
      Object.assign(foundHouse, house)
    },
    pagination(state, pagination) {
      state.pagination = pagination
    },
    reset(state) {
      state.houses = []
      state.page = 1
      state.selectionActive = false
    },
    changePage(state) {
      state.page++
    },
    // SELECTION
    setSelectionActive(state, isActive) {
      state.selectionActive = isActive
      state.houses.forEach(house => {
        house.isSelected = false
      });
    },
    selectObject(state, params) {
      const house = state.houses.find(x => x.id === params.id)
      house.isSelected = params.isSelected
    },
    // FILTER PARAMS
    filterParamsLocal(state, params) {
      state.filterParamsLocal = params
    },
    filterParamsSend(state, params) {
      state.filterParamsSend = params
    },
    filterParamsActive(state, number) {
      state.filterParamsActive = number
    },
    resetFilterParams(state) {
      state.filterParams = {}
      state.filterParamsActive = {}
    },
    searchParams(state, params) {
      state.searchParams = params
    }
  },
  actions: {
    async fetch({commit, getters}) {
      const page = getters.page
      try {
        const url = process.env.VUE_APP_BACKEND
        const houses = await axios.get(url + '/archive/properties/houses', {
          params: {
            page,
            ...getters.filterParamsSend,
            ...getters.searchParams
          }
        })
        commit('pagination', houses.data.pagination)
        commit('set', houses.data.properties.map(x => ({ ...x, isSelected: false })))
        commit('changePage')
      } catch (err) {
        commit('setError', err, {root: true})
        throw err
      }
    },
    async fetchHouse({commit}, id) {
      try {
        const url = process.env.VUE_APP_BACKEND
        const house = await axios.get(url + '/archive/properties/houses/' + id)
        commit('setHouse', house.data.item)
      } catch (err) {
        commit('setError', err, {root: true})
        throw err
      }
    },
    async restore({commit, dispatch}, id) {
      const url = process.env.VUE_APP_BACKEND
      try {
        await axios.patch(url + '/archive/properties/houses/' + id + '/restore')
        commit('reset')
        dispatch('fetch')
      } catch (err) {
        commit('setError', err, {root: true})
        throw err
      }
    },
    async restoreSelected({state, commit, dispatch}) {
      const url = process.env.VUE_APP_BACKEND
      try {
        const ids = state.houses.filter(x => x.isSelected).map(x => x.id)
        await axios.patch(url + '/archive/properties/houses/restore', { ids })
        commit('reset')
        dispatch('fetch')
      } catch (err) {
        commit('setError', err, {root: true})
        throw err
      }
    },
    async delete({commit, dispatch}, id) {
      const url = process.env.VUE_APP_BACKEND
      try {
        await axios.delete(url + '/archive/properties/houses/' + id)
        commit('reset')
        dispatch('fetch')
      } catch (err) {
        commit('setError', err, {root: true})
        throw err
      }
    },
    async deleteSelected({state, commit, dispatch}) {
      const url = process.env.VUE_APP_BACKEND
      try {
        const ids = state.houses.filter(x => x.isSelected).map(x => x.id)
        await axios.delete(url + '/archive/properties/houses/', { data: { ids } })
        commit('reset')
        dispatch('fetch')
      } catch (err) {
        commit('setError', err, {root: true})
        throw err
      }
    },
    async update({commit}, {id, data}) {
      const url = process.env.VUE_APP_BACKEND
      try {
        const response = await axios.put(url + '/archive/properties/houses/' + id, data)
        commit('updateHouse', response.data.item)
      } catch (err) {
        commit('setError', err, {root: true})
        throw err
      }
    }
  },
  getters: {
    houses: s => s.houses,
    house: s => s.house,
    pagination: s => s.pagination,
    page: s => s.page,
    // FILTER PARAMS
    filterParamsOriginal: s => s.filterParamsOriginal,
    filterParamsLocal: s => s.filterParamsLocal,
    filterParamsSend: s => s.filterParamsSend,
    filterParamsActive: s => s.filterParamsActive,
    sortParams: s => s.sortParams,
    searchParams: s => s.searchParams,
    selectionActive: s => s.selectionActive
  }
}
