<template>
  <div class="sidebar__wrapper">
    <div class="sidebar">
      <div class="sidebar__inner">
        <div class="sidebar__header"></div>
        <div class="sidebar__main">
          <div
            class="link link__sidebar link__sidebar-list"
            :class="{
              'link__sidebar--active':
                componentName === 'ObjectsApartment' ||
                componentName === 'ObjectsHouse' ||
                componentName === 'ObjectsCommerce'
            }"
          >
            <div class="link__sidebar-top">
              <i class="ri-database-line link__sidebar_icon"></i>
              <span class="link__text link__sidebar_text">Об'єкти</span>
            </div>
            <router-link
              to="/objects/apartments"
              class="link__sidebar-bottom"
              active-class="link__sidebar--active"
              @click.native="hideSidebar"
              >Квартири</router-link
            >
            <router-link
              to="/objects/houses"
              class="link__sidebar-bottom"
              active-class="link__sidebar--active"
              @click.native="hideSidebar"
              >Будинки</router-link
            >
            <router-link
              to="/objects/commerce"
              class="link__sidebar-bottom"
              active-class="link__sidebar--active"
              @click.native="hideSidebar"
              >Комерція</router-link
            >
          </div>
          <div
            class="link link__sidebar link__sidebar-list"
            :class="{
              'link__sidebar--active':
                componentName === 'buyersApt' || componentName === 'buyersHouse' || componentName === 'buyersCom'
            }"
          >
            <div class="link__sidebar-top">
              <i class="ri-shopping-bag-fill link__sidebar_icon"></i>
              <span class="link__text link__sidebar_text">Покупці</span>
            </div>
            <router-link
              to="/buyers/apartments"
              class="link__sidebar-bottom"
              active-class="link__sidebar--active"
              @click.native="hideSidebar"
              >Квартири</router-link
            >
            <router-link
              to="/buyers/houses"
              class="link__sidebar-bottom"
              active-class="link__sidebar--active"
              @click.native="hideSidebar"
              >Будинки</router-link
            >
            <router-link
              to="/buyers/commerce"
              class="link__sidebar-bottom"
              active-class="link__sidebar--active"
              @click.native="hideSidebar"
              >Комерція</router-link
            >
          </div>
          <div
            class="link link__sidebar link__sidebar-list"
            :class="{
              'link__sidebar--active':
                componentName === 'RentApartment' ||
                componentName === 'RentHouse' ||
                componentName === 'RentCommerce'
            }"
          >
            <div class="link__sidebar-top">
              <i class="ri-lock-2-fill link__sidebar_icon"></i>
              <span class="link__text link__sidebar_text">Орендодавці</span>
            </div>
            <router-link
              to="/rent/apartments"
              class="link__sidebar-bottom"
              active-class="link__sidebar--active"
              @click.native="hideSidebar"
              >Квартири</router-link
            >
            <router-link
              to="/rent/houses"
              class="link__sidebar-bottom"
              active-class="link__sidebar--active"
              @click.native="hideSidebar"
              >Будинки</router-link
            >
            <router-link
              to="/rent/commerce"
              class="link__sidebar-bottom"
              active-class="link__sidebar--active"
              @click.native="hideSidebar"
              >Комерція</router-link
            >
          </div>
          <div
            class="link link__sidebar link__sidebar-list"
            :class="{
              'link__sidebar--active':
                componentName === 'rentersApt' || componentName === 'rentersHouse' || componentName === 'rentersCom'
            }"
          >
            <div class="link__sidebar-top">
              <i class="ri-key-2-fill link__sidebar_icon"></i>
              <span class="link__text link__sidebar_text">Орендарі</span>
            </div>
            <router-link
              to="/renters/apartments"
              class="link__sidebar-bottom"
              active-class="link__sidebar--active"
              @click.native="hideSidebar"
              >Квартири</router-link
            >
            <router-link
              to="/renters/houses"
              class="link__sidebar-bottom"
              active-class="link__sidebar--active"
              @click.native="hideSidebar"
              >Будинки</router-link
            >
            <router-link
              to="/renters/commerce"
              class="link__sidebar-bottom"
              active-class="link__sidebar--active"
              @click.native="hideSidebar"
              >Комерція</router-link
            >
          </div>
          <router-link
            v-if="$store.getters.user.rank === 4"
            to="/users"
            class="link link__sidebar"
            active-class="link__sidebar--active"
            @click.native="hideSidebar"
          >
            <i class="ri-user-line link__sidebar_icon"></i>
            <span class="link__text link__sidebar_text">Користувачі</span>
          </router-link>
          <router-link
            to="/contacts"
            class="link link__sidebar"
            active-class="link__sidebar--active"
            @click.native="hideSidebar"
          >
            <i class="ri-contacts-line link__sidebar_icon"></i>
            <span class="link__text link__sidebar_text">Контакти</span>
          </router-link>
          <router-link
            to="/seekers"
            class="link link__sidebar"
            active-class="link__sidebar--active"
            @click.native="hideSidebar"
          >
            <i class="ri-user-search-line link__sidebar_icon"></i>
            <span class="link__text link__sidebar_text">Шукачі</span>
          </router-link>
          <router-link
            to="/buildings"
            class="link link__sidebar"
            active-class="link__sidebar--active"
            @click.native="hideSidebar"
          >
            <i class="ri-hotel-line link__sidebar_icon"></i>
            <span class="link__text link__sidebar_text">Рік будинку</span>
          </router-link>
          <div
            v-if="$store.getters.user.rank === 4"
            class="link link__sidebar link__sidebar-list"
            :class="{
              'link__sidebar--active':
                componentName === 'StatsObjects' ||
                componentName === 'StatsBuyers' ||
                componentName === 'StatsActions'
            }"
          >
            <div class="link__sidebar-top">
              <i class="ri-bar-chart-box-line link__sidebar_icon"></i>
              <span class="link__text link__sidebar_text">Статистика</span>
            </div>
            <router-link
              to="/stats/objects"
              class="link__sidebar-bottom"
              active-class="link__sidebar--active"
              @click.native="hideSidebar"
              >Об'єкти</router-link
            >
            <router-link
              to="/stats/buyers"
              class="link__sidebar-bottom"
              active-class="link__sidebar--active"
              @click.native="hideSidebar"
              >Покупці</router-link
            >
            <router-link
              to="/stats/actions"
              class="link__sidebar-bottom"
              active-class="link__sidebar--active"
              @click.native="hideSidebar"
              >Дії</router-link
            >
            <router-link
              to="/stats/seekers"
              class="link__sidebar-bottom"
              active-class="link__sidebar--active"
              @click.native="hideSidebar"
              >Шукачі</router-link
            >
          </div>
          <div class="line"></div>
          <router-link
            to="/actions"
            class="link link__sidebar"
            active-class="link__sidebar--active"
            @click.native="hideSidebar"
          >
            <i class="ri-focus-line link__sidebar_icon"></i>
            <span class="link__text link__sidebar_text">Дії</span>
          </router-link>
          <div
            class="link link__sidebar link__sidebar-list is-red"
            :class="{
              'link__sidebar--active':
                componentName === 'ArchiveObjectsApartments' ||
                componentName === 'ArchiveObjectsHouses' ||
                componentName === 'ArchiveObjectsCommerce'
            }"
          >
            <div class="link__sidebar-top">
              <i class="ri-database-line link__sidebar_icon"></i>
              <span class="link__text link__sidebar_text">Архів </span>
              <span class="link__text link__sidebar_text">Об'єкти</span>
            </div>
            <router-link
              to="/archive/objects/apartments"
              class="link__sidebar-bottom"
              active-class="link__sidebar--active"
              @click.native="hideSidebar"
              >Квартири</router-link
            >
            <router-link
              to="/archive/objects/houses"
              class="link__sidebar-bottom"
              active-class="link__sidebar--active"
              @click.native="hideSidebar"
              >Будинки</router-link
            >
            <router-link
              to="/archive/objects/commerce"
              class="link__sidebar-bottom"
              active-class="link__sidebar--active"
              @click.native="hideSidebar"
              >Комерція</router-link
            >
          </div>
          <div
            class="link link__sidebar link__sidebar-list is-red"
            :class="{
              'link__sidebar--active':
                componentName === 'ArchiveBuyersApartments' ||
                componentName === 'ArchiveBuyersHouses' ||
                componentName === 'ArchiveBuyersCommerce'
            }"
          >
            <div class="link__sidebar-top">
              <i class="ri-shopping-bag-fill link__sidebar_icon"></i>
              <span class="link__text link__sidebar_text">Архів</span>
              <span class="link__text link__sidebar_text">Покупці</span>
            </div>
            <router-link
              to="/archive/buyers/apartments"
              class="link__sidebar-bottom"
              active-class="link__sidebar--active"
              @click.native="hideSidebar"
              >Квартири</router-link
            >
            <router-link
              to="/archive/buyers/houses"
              class="link__sidebar-bottom"
              active-class="link__sidebar--active"
              @click.native="hideSidebar"
              >Будинки</router-link
            >
            <router-link
              to="/archive/buyers/commerce"
              class="link__sidebar-bottom"
              active-class="link__sidebar--active"
              @click.native="hideSidebar"
              >Комерція</router-link
            >
          </div>
          <div
            class="link link__sidebar link__sidebar-list is-red"
            :class="{
              'link__sidebar--active':
                componentName === 'ArchiveRentApartments' ||
                componentName === 'ArchiveRentHouses' ||
                componentName === 'ArchiveRentCommerce'
            }"
          >
            <div class="link__sidebar-top">
              <i class="ri-database-line link__sidebar_icon"></i>
              <span class="link__text link__sidebar_text">Архів </span>
              <span class="link__text link__sidebar_text">Орендодавці</span>
            </div>
            <router-link
              to="/archive/rent/apartments"
              class="link__sidebar-bottom"
              active-class="link__sidebar--active"
              @click.native="hideSidebar"
              >Квартири</router-link
            >
            <router-link
              to="/archive/rent/houses"
              class="link__sidebar-bottom"
              active-class="link__sidebar--active"
              @click.native="hideSidebar"
              >Будинки</router-link
            >
            <router-link
              to="/archive/rent/commerce"
              class="link__sidebar-bottom"
              active-class="link__sidebar--active"
              @click.native="hideSidebar"
              >Комерція</router-link
            >
          </div>
          <div
            class="link link__sidebar link__sidebar-list is-red"
            :class="{
              'link__sidebar--active':
                componentName === 'ArchiveRentersApartments' ||
                componentName === 'ArchiveRentersHouses' ||
                componentName === 'ArchiveRentersCommerce'
            }"
          >
            <div class="link__sidebar-top">
              <i class="ri-shopping-bag-fill link__sidebar_icon"></i>
              <span class="link__text link__sidebar_text">Архів</span>
              <span class="link__text link__sidebar_text">Орендарі</span>
            </div>
            <router-link
              to="/archive/renters/apartments"
              class="link__sidebar-bottom"
              active-class="link__sidebar--active"
              @click.native="hideSidebar"
              >Квартири</router-link
            >
            <router-link
              to="/archive/renters/houses"
              class="link__sidebar-bottom"
              active-class="link__sidebar--active"
              @click.native="hideSidebar"
              >Будинки</router-link
            >
            <router-link
              to="/archive/renters/commerce"
              class="link__sidebar-bottom"
              active-class="link__sidebar--active"
              @click.native="hideSidebar"
              >Комерція</router-link
            >
          </div>

          <div
            class="link link__sidebar link__sidebar-list"
            :class="{
              'link__sidebar--active':
                componentName === 'FavoriteObjectsApartments' ||
                componentName === 'FavoriteObjectsHouses' ||
                componentName === 'FavoriteObjectsCommerce'
            }"
          >
            <div class="link__sidebar-top">
              <i class="ri-database-line link__sidebar_icon"></i>
              <span class="link__text link__sidebar_text">Збережені </span>
              <span class="link__text link__sidebar_text">Об'єкти</span>
            </div>
            <router-link
              to="/favorites/objects/apartments"
              class="link__sidebar-bottom"
              active-class="link__sidebar--active"
              @click.native="hideSidebar"
              >Квартири</router-link
            >
            <router-link
              to="/favorites/objects/houses"
              class="link__sidebar-bottom"
              active-class="link__sidebar--active"
              @click.native="hideSidebar"
              >Будинки</router-link
            >
            <router-link
              to="/favorites/objects/commerce"
              class="link__sidebar-bottom"
              active-class="link__sidebar--active"
              @click.native="hideSidebar"
              >Комерція</router-link
            >
          </div>
          <div
            class="link link__sidebar link__sidebar-list"
            :class="{
              'link__sidebar--active':
                componentName === 'FavoriteBuyersApartments' ||
                componentName === 'FavoriteBuyersHouses' ||
                componentName === 'FavoriteBuyersCommerce'
            }"
          >
            <div class="link__sidebar-top">
              <i class="ri-shopping-bag-fill link__sidebar_icon"></i>
              <span class="link__text link__sidebar_text">Збережені </span>
              <span class="link__text link__sidebar_text">Покупці</span>
            </div>
            <router-link
              to="/favorites/buyers/apartments"
              class="link__sidebar-bottom"
              active-class="link__sidebar--active"
              @click.native="hideSidebar"
              >Квартири</router-link
            >
            <router-link
              to="/favorites/buyers/houses"
              class="link__sidebar-bottom"
              active-class="link__sidebar--active"
              @click.native="hideSidebar"
              >Будинки</router-link
            >
            <router-link
              to="/favorites/buyers/commerce"
              class="link__sidebar-bottom"
              active-class="link__sidebar--active"
              @click.native="hideSidebar"
              >Комерція</router-link
            >
          </div>
          <div
            class="link link__sidebar link__sidebar-list"
            :class="{
              'link__sidebar--active':
                componentName === 'FavoriteRentApartments' ||
                componentName === 'FavoriteRentHouses' ||
                componentName === 'FavoriteRentCommerce'
            }"
          >
            <div class="link__sidebar-top">
              <i class="ri-database-line link__sidebar_icon"></i>
              <span class="link__text link__sidebar_text">Збережені </span>
              <span class="link__text link__sidebar_text">Орендодавці</span>
            </div>
            <router-link
              to="/favorites/rent/apartments"
              class="link__sidebar-bottom"
              active-class="link__sidebar--active"
              @click.native="hideSidebar"
              >Квартири</router-link
            >
            <router-link
              to="/favorites/rent/houses"
              class="link__sidebar-bottom"
              active-class="link__sidebar--active"
              @click.native="hideSidebar"
              >Будинки</router-link
            >
            <router-link
              to="/favorites/rent/commerce"
              class="link__sidebar-bottom"
              active-class="link__sidebar--active"
              @click.native="hideSidebar"
              >Комерція</router-link
            >
          </div>
          <div
            class="link link__sidebar link__sidebar-list"
            :class="{
              'link__sidebar--active':
                componentName === 'FavoriteRentersApartments' ||
                componentName === 'FavoriteRentersHouses' ||
                componentName === 'FavoriteRentersCommerce'
            }"
          >
            <div class="link__sidebar-top">
              <i class="ri-shopping-bag-fill link__sidebar_icon"></i>
              <span class="link__text link__sidebar_text">Збережені </span>
              <span class="link__text link__sidebar_text">Орендарі</span>
            </div>
            <router-link
              to="/favorites/renters/apartments"
              class="link__sidebar-bottom"
              active-class="link__sidebar--active"
              @click.native="hideSidebar"
              >Квартири</router-link
            >
            <router-link
              to="/favorites/renters/houses"
              class="link__sidebar-bottom"
              active-class="link__sidebar--active"
              @click.native="hideSidebar"
              >Будинки</router-link
            >
            <router-link
              to="/favorites/renters/commerce"
              class="link__sidebar-bottom"
              active-class="link__sidebar--active"
              @click.native="hideSidebar"
              >Комерція</router-link
            >
          </div>
        </div>
        <div class="sidebar__footer"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SidebarMobile',
  computed: {
    componentName() {
      return this.$route.name
    }
  },
  methods: {
    hideSidebar() {
      this.$modal.hide('Sidebar')
    }
  }
}
</script>

<style lang="scss" scoped>
@import './style.scss';
</style>
