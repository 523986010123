import axios from 'axios'

export default {
  state: {
    actions: [],
    action: {},
    pagActions: {},
    pageActions: 1,
    searchParams: {},
    filterParamsActions: {},
    filterParamsActionsOriginal: {
      agentID: [],
      actionType: [],
      productionType: [],
      propertyType: [],
      buyerType: [],
      result: [],
      createdAt: {},
      actionAt: {},
      productionNumber: {},
    },
    filterParamsActionsAll: {},
    filterActiveParams: 0
  },
  mutations: {
    setActions(state, actions) {
      state.actions = state.actions.concat(actions)
    },
    setAction(state, action) {
      state.actionsShow = action
    },
    updateAction(state, action) {
      const foundAction = state.actions.find(x => x.id === action.id)
      Object.assign(foundAction, action)
    },
    paginationActions(state, pagAct) {
      state.pagActions = pagAct
    },
    resetActions(state) {
      state.actions = []
      state.pageActions = 1
    },
    changePageActions(state) {
      state.pageActions++
    },
    filterParamsActions(state, params) {
      state.filterParamsActions = params
    },
    filterParamsActionsAll(state, params) {
      state.filterParamsActionsAll = params
    },
    filterActiveParams(state, params) {
      state.filterActiveParams = params
    },
    searchParams(state, params) {
      state.searchParams = params
    }
  },
  actions: {
    async fetchActions({commit, getters, dispatch}, id) {
      let page = getters.pageActions
      if (Object.keys(getters.user).length === 0) {
        await dispatch('getUser')
      }
      const user = getters.user
      let path = String
      if (user.role === 4) {
        path = '/actions'
      } else {
        path = '/users/me/actions'
      }
      try {
        const url = process.env.VUE_APP_BACKEND
        const actions = await axios.get(url + path, {
          params: {
            page,
            ...getters.filterParamsActions,
            ...getters.searchParams
          }
        })
        const changedActions = actions.data.actions.map(action => {
          const time = action.actionAt.substring(action.actionAt.search('T') + 1, action.actionAt.search('T') + 6)
          return {...action, time}
        })
        commit('paginationActions', actions.data.pagination)
        commit('setActions', changedActions)
        commit('changePageActions')
      } catch (e) {
        commit('setError', e)
      }
    },
    async delActions({commit, dispatch}, id) {
      try {
        const url = process.env.VUE_APP_BACKEND
        await axios.delete(url + '/actions/' + id)
        commit('resetActions')
        dispatch('fetchActions')
      } catch (e) {
        commit('setError', e)
      }
    },
    async createAction({commit, dispatch}, { data }) {
      try {
        const url = process.env.VUE_APP_BACKEND
        await axios.post(url + '/actions', data)
        commit('resetActions')
        await dispatch('fetchActions')
      } catch (e) {
        commit('setError', e)
        throw e
      }
    },
    async editAction({commit}, {id, data}) {
      try {
        const url = process.env.VUE_APP_BACKEND
        const response = await axios.put(url + '/actions/' + id, data)
        commit('updateAction', response.data.action)
      } catch (e) {
        commit('setError', e)
        throw e
      }
    }
  },
  getters: {
    actions: s => s.actions,
    action: s => s.action,
    pagActions: s => s.pagActions,
    pageActions: s => s.pageActions,
    filterParamsActions: s => s.filterParamsActions,
    filterParamsActionsOriginal: s => s.filterParamsActionsOriginal,
    filterParamsActionsAll: s => s.filterParamsActionsAll,
    filterActiveParams: s => s.filterActiveParams,
    searchParams: s => s.searchParams
  }
}
