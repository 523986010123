var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.type === "info-row"
    ? _c(
        "div",
        {
          staticClass: "rel__block",
          class: { "rel__block--golden": _vm.relation.isGolden },
          on: { click: _vm.openRelation }
        },
        [
          _c("div", {
            staticClass: "rel__circle",
            class: { "rel__circle--red": _vm.isArchived }
          }),
          _vm.relation.contactableType === "properties"
            ? _c("div", { staticClass: "rel__text" }, [_vm._v("Об'єкт / ")])
            : _vm._e(),
          _vm.relation.contactableType === "buyers"
            ? _c("div", { staticClass: "rel__text" }, [_vm._v("Покупець / ")])
            : _vm._e(),
          _vm.relation.contactableType === "seekers"
            ? _c("div", { staticClass: "rel__text" }, [_vm._v("Шукач / ")])
            : _vm._e(),
          _vm.relation.contactableType === "buildings"
            ? _c("div", { staticClass: "rel__text" }, [
                _vm._v("Рік Будинку / ")
              ])
            : _vm._e(),
          _vm.relation.contactableSubtype === "apartment"
            ? _c("div", { staticClass: "rel__text" }, [_vm._v("Квартира / ")])
            : _vm._e(),
          _vm.relation.contactableSubtype === "house"
            ? _c("div", { staticClass: "rel__text" }, [_vm._v("Будинок / ")])
            : _vm._e(),
          _vm.relation.contactableSubtype === "commerce"
            ? _c("div", { staticClass: "rel__text" }, [_vm._v("Комерція / ")])
            : _vm._e(),
          _c("div", { staticClass: "rel__text" }, [
            _vm._v("ID " + _vm._s(_vm.relation.contactableId) + " / ")
          ]),
          _c("div", { staticClass: "rel__text" }, [
            _vm._v(_vm._s(_vm._f("date")(_vm.relation.createdAt)))
          ]),
          _vm.relation.isSoldByOurAgency
            ? _c("div", { staticClass: "rel__text" }, [_vm._v(" / Наше АН")])
            : _vm._e()
        ]
      )
    : _vm.type === "info-tile"
    ? _c(
        "div",
        {
          staticClass: "tile__item main__relationId",
          on: { click: _vm.openRelation }
        },
        [
          _vm.relation.contactableType === "buyers"
            ? _c("i", { staticClass: "ri-user-line" })
            : _vm._e(),
          _vm.relation.contactableType === "properties"
            ? _c("i", {
                class: {
                  "ri-building-4-line":
                    _vm.relation.contactableSubtype === "apartment",
                  "ri-home-5-line": _vm.relation.contactableSubtype === "house",
                  "ri-store-line":
                    _vm.relation.contactableSubtype === "commerce"
                }
              })
            : _vm._e(),
          _c("span", { staticStyle: { "margin-left": "6px" } }, [
            _vm._v(_vm._s(_vm.relation.contactableId))
          ])
        ]
      )
    : _vm.type === "card-tile"
    ? _c(
        "div",
        {
          staticClass: "card__rel",
          on: {
            click: function($event) {
              $event.stopPropagation()
              $event.preventDefault()
              return _vm.openRelation($event)
            }
          }
        },
        [
          _vm._v(" ID " + _vm._s(_vm.relation.contactableId) + " "),
          _c("i", { staticClass: "i-relation" })
        ]
      )
    : _vm.type === "notification-icon"
    ? _c(
        "div",
        {
          staticClass: "notification-icon",
          on: {
            click: function($event) {
              $event.stopPropagation()
              $event.preventDefault()
              return _vm.openRelation($event)
            }
          }
        },
        [
          _vm.relation.contactableType === "buyers"
            ? _c("i", { staticClass: "ri-user-line" })
            : _vm._e(),
          _vm.relation.contactableType === "properties"
            ? _c("i", {
                class: {
                  "ri-building-4-line":
                    _vm.relation.contactableSubtype === "apartment",
                  "ri-home-5-line": _vm.relation.contactableSubtype === "house",
                  "ri-store-line":
                    _vm.relation.contactableSubtype === "commerce"
                }
              })
            : _vm._e(),
          _c("span", { staticStyle: { "margin-left": "6px" } }, [
            _vm._v(_vm._s(_vm.relation.contactableId))
          ])
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }