<template>
  <div class="upload__container upload-action">
    <div class="upload__right">
      <div class="upload__top">
        <div class="upload__title">
          <span>Нова Дія</span>
        </div>
        <div class="upload__buttons">
          <div class="upload__button button button--fill button--grey" @click="closeModal">Скасувати</div>
          <div class="upload__button button button--fill" @click="send">Зберегти</div>
        </div>
      </div>
      <div class="upload__main">
        <div class="data__middle">
          <div
            class="field__label"
            :class="{
              'field__label--filled': actionType,
              'field__label--invalid': $v.actionType.$dirty && !$v.actionType.required
            }"
          >
            <div class="field__title">Тип дії</div>
            <Multiselect
              :options="constants.ACTION_TYPES"
              v-model="actionType"
              label="name"
              trackBy="id"
              :showLabels="false"
              :searchable="false"
              :maxHeight="200"
              class="mSelect"
              placeholder="тип"
            />
          </div>
          <div
            v-if="isActionProduction"
            class="field__label"
            :class="{
              'field__label--filled': productionType,
              'field__label--invalid': $v.productionType.$dirty && !$v.productionType.required
            }"
          >
            <div class="field__title">Тип продукції</div>
            <Multiselect
              :options="constants.ACTION_PRODUCTION_TYPES"
              v-model="productionType"
              label="name"
              trackBy="id"
              :showLabels="false"
              :searchable="false"
              :maxHeight="200"
              class="mSelect"
              placeholder="Тип продукції"
            />
          </div>
          <div
            v-if="!isActionProduction"
            class="field__label"
            :class="{
              'field__label--filled': result,
              'field__label--invalid': $v.result.$dirty && !$v.result.required
            }"
          >
            <div class="field__title">Результат дії</div>
            <Multiselect
              :options="constants.ACTION_RESULTS"
              v-model="result"
              label="name"
              trackBy="id"
              :showLabels="false"
              :searchable="false"
              :maxHeight="200"
              class="mSelect"
              placeholder="результат"
            />
          </div>
          <div
            v-if="(isActionShowing || isActionViewing || isActionCall) && isResultRejected"
            class="field__label"
            :class="{
              'field__label--filled': rejectionReason,
              'field__label--invalid': $v.rejectionReason.$dirty && !$v.rejectionReason.required
            }"
          >
          <div class="field__title">Причина</div>
            <Multiselect
              v-if="isActionShowing || isActionCall"
              :options="constants.ACTION_SHOWING_REJECTION_REASONS"
              v-model="rejectionReason"
              label="name"
              trackBy="id"
              :showLabels="false"
              :searchable="false"
              :maxHeight="200"
              class="mSelect"
              placeholder="причина"
            />
            <Multiselect
              v-if="isActionViewing"
              :options="constants.ACTION_VIEWING_REJECTION_REASONS"
              v-model="rejectionReason"
              label="name"
              trackBy="id"
              :showLabels="false"
              :searchable="false"
              :maxHeight="200"
              class="mSelect"
              placeholder="причина"
            />
          </div>
          <div
            v-if="(isActionShowing || isActionViewing || isActionCall) && isResultRejected && isRejectionReasonPrice"
            class="field__label field__label__icon--currency"
            :class="{
              'field__label--filled': buyerPrice,
              'field__label--invalid': $v.buyerPrice.$dirty && $v.buyerPrice.$error
            }"
          >
            <div class="field__title field__title--required">Ціна покупця</div>
            <input class="field__input" min="0" v-model.number="buyerPrice" placeholder="Ціна покупця" />
          </div>
        </div>

        <div v-if="isActionNotary" class="data__middle">
          <div
            class="field__label field__label__icon--currency"
            :class="{
              'field__label--filled': depositAmount,
              'field__label--invalid': $v.depositAmount.$dirty && $v.depositAmount.$error
            }"
          >
            <div class="field__title field__title--required">Сума завдатку</div>
            <input class="field__input" min="0" v-model.number="depositAmount" placeholder="Сума завдатку" />
          </div>
          <div
            class="field__label"
            :class="{
              'field__label--filled': depositEndDate,
              'field__label--invalid': $v.depositEndDate.$dirty && $v.depositEndDate.$error
            }"
          >
            <div class="field__title">Закінчення завдатку</div>
            <date-picker
              v-model="depositEndDate"
              mode="date"
              is24hr
              isDark
              :minute-increment="10"
              :popover="{visibility: 'focus', placement: 'top-start'}"
            >
              <template v-slot="{inputValue, inputEvents}">
                <input style="width: 100%" class="field__input" :value="inputValue" v-on="inputEvents" readonly />
              </template>
            </date-picker>
          </div>
        </div>
        <div v-if="isActionNotary" class="data__middle">
          <div class="field__label" :class="{
              'field__label--filled': notaryFullName,
              'field__label--invalid': $v.notaryFullName.$dirty && $v.notaryFullName.$error
            }">
            <div class="field__title">ПІБ нотаріуса</div>
            <Multiselect
              :options="localConstants.ACTION_NOTARIES"
              v-model="notaryFullName"
              :showLabels="false"
              :searchable="true"
              :taggable="true"
              :multiple="false"
              :maxHeight="200"
              class="mSelect"
              placeholder="ПІБ нотаріуса"
              tag-placeholder="Підтвердити"
              @tag="setNotary"
            >
            </Multiselect>
          </div>
          <div
            class="field__label"
            :class="{
              'field__label--filled': deedType,
              'field__label--invalid': $v.deedType.$dirty && $v.deedType.$error
            }"
          >
            <div class="field__title">Вид правочину</div>
            <Multiselect
              :options="constants.ACTION_DEED_TYPES"
              v-model="deedType"
              label="name"
              trackBy="id"
              :showLabels="false"
              :searchable="false"
              :maxHeight="200"
              class="mSelect"
              placeholder="Вид правочину"
            />
          </div>
        </div>
        <div v-if="isActionNotary" class="data__title">Витрати покупця</div>
        <div v-if="isActionNotary" class="data__triple">
          <div class="field__label field__label__icon--percent" :class="{
              'field__label--filled': buyerNotaryFee,
              'field__label--invalid': $v.buyerNotaryFee.$dirty && $v.buyerNotaryFee.$error
            }">
            <div class="field__title field__title--required">Послуги нотаріуса</div>
            <input class="field__input" v-model.number="buyerNotaryFee" placeholder="Послуги нотаріуса" />
          </div>
          <div class="field__label field__label__icon--percent" :class="{
              'field__label--filled': buyerTax,
              'field__label--invalid': $v.buyerTax.$dirty && $v.buyerTax.$error
            }">
            <div class="field__title field__title--required">Розмір податку</div>
            <input class="field__input" v-model.number="buyerTax" placeholder="Розмір податку" />
          </div>
          <div class="field__label field__label__icon--currency" :class="{
              'field__label--filled': buyerAgencyFee,
              'field__label--invalid': $v.buyerAgencyFee.$dirty && $v.buyerAgencyFee.$error
            }">
            <div class="field__title field__title--required">Комісія АН</div>
            <input class="field__input" v-model.number="buyerAgencyFee" placeholder="Комісія АН" />
          </div>
        </div>
        <div v-if="isActionNotary" class="data__title">Витрати власника</div>
        <div v-if="isActionNotary" class="data__triple">
          <div class="field__label field__label__icon--percent" :class="{
              'field__label--filled': sellerNotaryFee,
              'field__label--invalid': $v.sellerNotaryFee.$dirty && $v.sellerNotaryFee.$error
            }">
            <div class="field__title field__title--required">Послуги нотаріуса</div>
            <input class="field__input" v-model.number="sellerNotaryFee" placeholder="Послуги нотаріуса" />
          </div>
          <div class="field__label field__label__icon--percent" :class="{
              'field__label--filled': sellerTax,
              'field__label--invalid': $v.sellerTax.$dirty && $v.sellerTax.$error
            }">
            <div class="field__title field__title--required">Розмір податку</div>
            <input class="field__input" v-model.number="sellerTax" placeholder="Розмір податку" />
          </div>
          <div class="field__label field__label__icon--currency" :class="{
              'field__label--filled': sellerAgencyFee,
              'field__label--invalid': $v.sellerAgencyFee.$dirty && $v.sellerAgencyFee.$error
            }">
            <div class="field__title field__title--required">Комісія АН</div>
            <input class="field__input" v-model.number="sellerAgencyFee" placeholder="Комісія АН" />
          </div>
        </div>

        <div v-if="isAdmin || isProductionRequiresObject" class="data__middle">
          <div v-if="isAdmin" class="field__label" :class="{'field__label--filled': userID}">
            <div class="field__title">Агент</div>
            <Multiselect
              :options="users"
              v-model="userID"
              label="fullName"
              trackBy="id"
              :showLabels="false"
              :searchable="false"
              :maxHeight="200"
              class="mSelect"
              placeholder="Агент"
            />
          </div>
          <label
            v-if="isProductionRequiresObject"
            class="field__label"
            :class="{
              'field__label--filled': propertyRelatedID,
              'field__label--invalid': ($v.propertyRelatedID.$dirty && !$v.propertyRelatedID.required) || !$v.propertyRelatedID.exists
            }"
          >
            <div class="field__title field__title--required">ID Об'єкту</div>
            <input class="field__input" v-model="propertyRelatedID" placeholder="ID об'єкту" />
          </label>
        </div>
        <div v-if="!isActionProduction" class="data__middle">
          <div class="field__label" :class="{'field__label--filled': propertyType}">
            <div class="field__title">Тип об'єкта</div>
            <Multiselect
              :options="constants.ACTION_RELATED_TYPES"
              v-model="propertyType"
              label="name"
              trackBy="id"
              :showLabels="false"
              :searchable="false"
              :maxHeight="200"
              class="mSelect"
              open-direction="bottom"
              placeholder="тип"
            />
          </div>
          <label
            v-if="propertyType && propertyType.id === 2"
            class="field__label"
            :class="{
              'field__label--filled': propertyRelatedID,
              'field__label--invalid': !$v.propertyRelatedID.exists
            }"
          >
            <div class="field__title field__title--required">ID Об'єкту</div>
            <input class="field__input" v-model="propertyRelatedID" placeholder="ID об'єкту" />
          </label>
          <label
            v-if="propertyType && propertyType.id === 3"
            class="field__label"
            :class="{
              'field__label--filled': propertyRelatedID,
              'field__label--invalid': $v.propertyRelatedID.$dirty && !$v.propertyRelatedID.required
            }"
          >
            <div class="field__title field__title--required">ID Об'єкту (AH)</div>
            <input class="field__input" v-model="propertyRelatedID" placeholder="ID об'єкту (AH)" />
          </label>
          <div
            v-else-if="propertyType && propertyType.id === 4"
            class="field__label"
            :class="{
              'field__label--filled': userID,
              'field__label--invalid': $v.userID.$dirty && !$v.userID.required
            }"
          >
            <div class="field__title">Агент</div>
            <Multiselect
              :options="users"
              v-model="userID"
              label="fullName"
              trackBy="id"
              :showLabels="false"
              :searchable="false"
              :maxHeight="200"
              class="mSelect"
              placeholder="Агент"
            >
            </Multiselect>
          </div>
        </div>
        <div v-if="!isActionProduction" class="data__middle">
          <div class="field__label" :class="{'field__label--filled': buyerType}">
            <div class="field__title">Покупець/орендар</div>
            <Multiselect
              :options="constants.ACTION_RELATED_TYPES"
              v-model="buyerType"
              label="name"
              trackBy="id"
              :showLabels="false"
              :searchable="false"
              :maxHeight="200"
              class="mSelect"
              open-direction="bottom"
              placeholder="покупець/орендар"
            />
          </div>
          <label
            v-if="buyerType && buyerType.id === 2"
            class="field__label"
            :class="{
              'field__label--filled': buyerRelatedID,
              'field__label--invalid': !$v.buyerRelatedID.exists
            }"
          >
            <div class="field__title field__title--required">ID покупця/орендаря</div>
            <input class="field__input" v-model="buyerRelatedID" placeholder="ID покупця/орендаря" />
          </label>
          <label
            v-if="buyerType && buyerType.id === 3"
            class="field__label"
            :class="{
              'field__label--filled': buyerRelatedID,
              'field__label--invalid': $v.buyerRelatedID.$dirty && !$v.buyerRelatedID.required
            }"
          >
            <div class="field__title field__title--required">ID покупця/орендаря (AH)</div>
            <input class="field__input" v-model="buyerRelatedID" placeholder="ID покупця/орендаря (АН)" />
          </label>
          <div
            v-else-if="buyerType && buyerType.id === 4"
            class="field__label"
            :class="{
              'field__label--filled': buyuserID,
              'field__label--invalid': $v.buyuserID.$dirty && !$v.buyuserID.required
            }"
          >
            <div class="field__title">Агент</div>
            <Multiselect
              :options="users"
              v-model="buyuserID"
              label="fullName"
              trackBy="id"
              :showLabels="false"
              :searchable="false"
              :maxHeight="200"
              class="mSelect"
              placeholder="Агент"
            >
            </Multiselect>
          </div>
        </div>
        <div class="data__middle">
          <div
            class="field__label"
            :class="{
              'field__label--filled': actionAt,
              'field__label--invalid': $v.actionAt.$dirty && !$v.actionAt.required
            }"
          >
            <div class="field__title">Дата і час дії</div>
            <date-picker
              v-model="actionAt"
              mode="dateTime"
              is24hr
              isDark
              :minute-increment="10"
              :popover="{visibility: 'focus', placement: 'top-start'}"
            >
              <template v-slot="{inputValue, inputEvents}">
                <input style="width: 100%" class="field__input" :value="inputValue" v-on="inputEvents" readonly />
              </template>
            </date-picker>
          </div>
          <label
            v-if="isActionProduction"
            class="field__label"
            :class="{
              'field__label--filled': productionNumber,
              'field__label--invalid': $v.productionNumber.$dirty && !$v.productionNumber.required
            }"
          >
            <div class="field__title field__title--required">Кількість</div>
            <input class="field__input" v-model="productionNumber" placeholder="Кількість" />
          </label>
        </div>
        <div class="data__bottom">
          <label
            class="field__label"
            :class="{
              'field__label--filled': description,
              'field__label--invalid': $v.description.$dirty && !$v.description.required
            }"
          >
            <div class="field__title">Деталі</div>
            <textarea class="field__text" v-model="description"></textarea>
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {required, requiredIf, numeric} from 'vuelidate/lib/validators'
import localConstants from '@/utils/localConstants'
import Multiselect from 'vue-multiselect'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import moment from 'moment'
import axios from 'axios'

export default {
  name: 'UploadAction',
  props: {
    action: Object
  },
  watch: {
    actionType(newActionType, oldActionType) {
      if (oldActionType) {
        this.rejectionReason = null
      }
    } 
  },
  computed: {
    isActionShowing() {
      return this.actionType?.id === 1
    },
    isActionViewing() {
      return this.actionType?.id === 2
    },
    isActionCall() {
      return this.actionType?.id === 4
    },
    isActionNotary() {
      return this.actionType?.id === 6
    },
    isActionProduction() {
      return this.actionType?.id === 8
    },
    isResultRejected() {
      return this.result?.id === 2
    },
    isRejectionReasonPrice() {
      return this.rejectionReason?.id === 1
    },
    isProductionRequiresObject() {
      return this.productionType && (this.productionType.id === 6 || this.productionType.id === 7 || this.productionType.id === 8);
    },
  },
  components: {
    Multiselect,
    DatePicker
  },
  data: () => ({
    localConstants,
    constants: null,
    users: [],
    isAdmin: false,
    actionType: null,
    result: null,
    rejectionReason: null,
    buyerPrice: null,
    propertyType: null,
    buyerType: null,
    agent: null,
    buyuserID: null,
    actionAt: null,
    description: null,
    propertyRelatedID: null,
    buyerRelatedID: null,
    userID: null,
    depositAmount: null,
    depositEndDate: null,
    notaryFullName: null,
    deedType: null,
    buyerNotaryFee: null,
    buyerTax: null,
    buyerAgencyFee: null,
    sellerNotaryFee: null,
    sellerTax: null,
    sellerAgencyFee: null,
    productionType: null,
    productionNumber: null,
  }),
  methods: {
    async send() {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }

      let propertyRelatedID = this.getPropertyRelatedID()
      let buyerRelatedID = this.getBuyerRelatedID()

      let rejectionReasonID, buyerPrice
      if ((this.isActionShowing || this.isActionViewing || this.isActionCall) && this.isResultRejected) {
        rejectionReasonID = this.rejectionReason?.id
        buyerPrice = rejectionReasonID === 1 ? this.buyerPrice : null
      }
      
      const data = {
        actionType:      this.actionType.id,
        result:          this.result?.id || null,
        rejectionReason: rejectionReasonID,
        buyerPrice,
        propertyType:    this.propertyType?.id || 1,
        buyerType:       this.buyerType?.id || 1,
        actionAt:        this.getDate(this.actionAt, true),
        details:         this.description,  
        propertyRelatedID,
        buyerRelatedID,
        depositAmount:   this.isActionNotary ? this.depositAmount : null,
        depositEndDate:  this.isActionNotary ? this.getDate(this.depositEndDate) : null,
        notaryFullName:  this.isActionNotary ? this.notaryFullName : null,
        deedType:        this.isActionNotary ? this.deedType?.id : null,
        buyerNotaryFee:  this.isActionNotary ? this.buyerNotaryFee : null,
        buyerTax:        this.isActionNotary ? this.buyerTax : null,
        buyerAgencyFee:  this.isActionNotary ? this.buyerAgencyFee : null,
        sellerNotaryFee: this.isActionNotary ? this.sellerNotaryFee : null,
        sellerTax:       this.isActionNotary ? this.sellerTax : null,
        sellerAgencyFee: this.isActionNotary ? this.sellerAgencyFee : null,
        productionType:  this.productionType?.id ?? null,
        productionNumber: this.isActionProduction ? this.productionNumber : null,
      }
      try {
        const action = this.action ? 'editAction' : 'createAction'
        await this.$store.dispatch(action, { id: this.action?.id, data })
        this.$modal.hide('modalUploadAction')
      } catch (e) {}
    },
    closeModal() {
      this.$modal.hide('modalUploadAction')
    },
    setNotary (notary) {
      this.notaryFullName = notary;
    },
    getPropertyRelatedID() {
      if (this.isProductionRequiresObject) {
        return this.propertyRelatedID || null
      }
      switch (this.propertyType?.id) {
        case 2:
        case 3:
          return this.propertyRelatedID || null
        case 4:
          return this.userID.id
        default:
          return undefined
      }
    },
    getBuyerRelatedID() {
      switch (this.buyerType?.id) {
        case 2:
        case 3:
         return this.buyerRelatedID  || null
        case 4:
         return this.buyuserID.id
        default:
         return undefined
      }
    },
    getDate(date, isTimeIncluded = false) {
      if (!date) return null

      const isUtc = typeof date === 'string'
      const format = isTimeIncluded ? 'DD-MM-YYYY HH:mm' : 'DD-MM-YYYY'

      return isUtc ? moment.utc(date).format(format) : moment(date).format(format)
    }
  },
  async created() {
    const constants = (this.constants = this.$store.getters.constants)
    this.isAdmin = this.$store.getters.user.role === 4 ? true : false
    if (!this.$store.getters.users.length) {
      await this.$store.dispatch('fetchUsers')
    }
    this.users = this.$store.getters.users
    if (this.action) {
      this.actionType = {id: this.action.actionType, name: this.action.actionName}
      this.result = {id: this.action.result, name: this.action.resultName}
      this.propertyType = constants.ACTION_RELATED_TYPES.find(c => c.id === this.action.propertyType)
      this.buyerType = constants.ACTION_RELATED_TYPES.find(c => c.id === this.action.buyerType)
      if (this.isActionShowing || this.isActionCall) {
        this.rejectionReason = constants.ACTION_SHOWING_REJECTION_REASONS.find(c => c.id === this.action.rejectionReason)
      } else if (this.isActionViewing) {
        this.rejectionReason = constants.ACTION_VIEWING_REJECTION_REASONS.find(c => c.id === this.action.rejectionReason)
      }
      this.buyerPrice = this.action.buyerPrice
      this.description = this.action.details
      this.actionAt = this.action.actionAt
      this.depositAmount = this.action.depositAmount
      this.depositEndDate = this.action.depositEndDate
      this.notaryFullName = this.action.notaryFullName
      this.deedType = constants.ACTION_DEED_TYPES.find(c => c.id === this.action.deedType)
      this.buyerNotaryFee = this.action.buyerNotaryFee
      this.buyerTax = this.action.buyerTax
      this.buyerAgencyFee = this.action.buyerAgencyFee
      this.sellerNotaryFee = this.action.sellerNotaryFee
      this.sellerTax = this.action.sellerTax
      this.sellerAgencyFee = this.action.sellerAgencyFee
      this.productionType = constants.ACTION_PRODUCTION_TYPES.find(c => c.id === this.action.productionType)
      this.productionNumber = this.action.productionNumber

      switch (this.action.propertyType) {
        case 2:
        case 3:
          this.propertyRelatedID = this.action.propertyRelatedID
          break
        case 4:
          this.userID = this.users.find(u => u.id === this.action.propertyRelatedID)
          break
      }
      switch (this.action.buyerType) {
        case 2:
        case 3:
          this.buyerRelatedID = this.action.buyerRelatedID
          break
        case 4:
          this.buyuserID = this.users.find(u => u.id === this.action.buyerRelatedID)
          break
      }

      if (this.isProductionRequiresObject) {
        this.propertyRelatedID = this.action.propertyRelatedID
      }
    }
  },
  validations: {
    actionType: {required},
    result: {
      required: requiredIf(function() {
        return !this.isActionProduction
      })
    },
    actionAt: {required},
    description: {required},
    rejectionReason: {
      required: requiredIf(function() {
        return (this.isActionShowing || this.isActionViewing || this.isActionCall) && this.isResultRejected
      })
    },
    buyerPrice: {
      numeric,
      required: requiredIf(function() {
        return (this.isActionShowing || this.isActionCall) && this.isResultRejected && this.isRejectionReasonPrice
      })
    },
    depositAmount: {
      numeric,
      required: requiredIf(function() { return this.isActionNotary })
    },
    depositEndDate: {
      required: requiredIf(function() { return this.isActionNotary })
    },
    notaryFullName: {
      required: requiredIf(function() { return this.isActionNotary })
    },
    deedType: {
      required: requiredIf(function() { return this.isActionNotary })
    },
    buyerNotaryFee: {
      numeric,
      required: requiredIf(function() { return this.isActionNotary })
    },
    buyerTax: {
      numeric,
      required: requiredIf(function() { return this.isActionNotary })
    },
    buyerAgencyFee: {
      numeric,
      required: requiredIf(function() { return this.isActionNotary })
    },
    sellerNotaryFee: {
      numeric,
      required: requiredIf(function() { return this.isActionNotary })
    },
    sellerTax: {
      numeric,
      required: requiredIf(function() { return this.isActionNotary })
    },
    sellerAgencyFee: {
      numeric,
      required: requiredIf(function() { return this.isActionNotary })
    },
    productionType: {
      required: requiredIf(function() { return this.isActionProduction })
    },
    productionNumber: {
      numeric,
      required: requiredIf(function() { return this.isActionProduction })
    },
    propertyRelatedID: {
      required: requiredIf(function() { return this.isProductionRequiresObject }),
      exists(value) {
        if (this.propertyType?.id !== 2 && !this.isProductionRequiresObject) return true
        if (value == null || value === '') return true
        if (isNaN(Number(value))) return false

        return axios.get(`${process.env.VUE_APP_BACKEND}/properties/${value}/exists`, {})
          .then(res => Boolean(res.data?.exists))
          .catch(() => false)
      }
    },
    userID: {
      required: requiredIf(function() {
        return this.propertyType?.id === 4
      })
    },
    buyuserID: {
      required: requiredIf(function() {
        return this.buyerType?.id === 4
      })
    },
    buyerRelatedID: {
      required: false,
      exists(value) {
        if (this.buyerType?.id !== 2) return true
        if (value == null || value === '') return true
        if (isNaN(Number(value))) return false

        return axios.get(`${process.env.VUE_APP_BACKEND}/buyers/${value}/exists`, {})
          .then(res => Boolean(res.data?.exists))
          .catch(() => false)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'style';
.upload__main {
  overflow-y: scroll;
}
</style>

<style lang="scss">
.upload-action .vc-popover-content-wrapper {
  position: fixed !important;
}
</style>
