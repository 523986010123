var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "del__inner" }, [
    _c("div", { staticClass: "del__header" }),
    _c("div", { staticClass: "del__main" }, [
      _c("div", { staticClass: "del__main-header" }, [
        _c("div", {}, [
          _vm._v(
            "Цю дію не можна скасувати. Це призведе до остаточного видалення користувача."
          )
        ]),
        _c("div", {}, [
          _vm._v(
            _vm._s(_vm.user.firstName) +
              " " +
              _vm._s(_vm.user.lastName) +
              " (" +
              _vm._s(_vm.user.fullName) +
              ")"
          )
        ])
      ]),
      _c("div", { staticClass: "del__main-action" }, [
        _c("label", { staticClass: "field__label" }, [
          _c("div", { staticClass: "field__title" }, [
            _vm._v(
              "Введіть " +
                _vm._s(_vm.user.firstName) +
                " " +
                _vm._s(_vm.user.lastName) +
                " для підтвердження."
            )
          ]),
          _c("input", {
            staticClass: "field__input",
            attrs: {
              placeholder: _vm.user.firstName + " " + _vm.user.lastName
            },
            domProps: { value: _vm.name },
            on: { input: _vm.valideteDelete }
          })
        ]),
        _c(
          "button",
          {
            staticClass: "button button--fill is-red",
            class: { "is-disabled": _vm.isDisabled },
            attrs: { id: "del-btn" },
            on: { click: _vm.deleteUser }
          },
          [_vm._v(" Видалити ")]
        )
      ])
    ]),
    _vm.isBoom
      ? _c("div", { staticClass: "boom" }, [
          _c("img", {
            attrs: { src: require("@/assets/images/animation.gif"), alt: "" }
          })
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }