var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "sidebar__wrapper" }, [
    _c("div", { staticClass: "sidebar" }, [
      _c("div", { staticClass: "sidebar__inner" }, [
        _c("div", { staticClass: "sidebar__header" }),
        _c(
          "div",
          { staticClass: "sidebar__main" },
          [
            _c(
              "router-link",
              {
                staticClass: "link link__sidebar",
                attrs: {
                  to: "/objects/",
                  "active-class": "link__sidebar--active"
                },
                nativeOn: {
                  click: function($event) {
                    return _vm.hideSidebar($event)
                  }
                }
              },
              [
                _c("i", { staticClass: "ri-database-line link__sidebar_icon" }),
                _c("span", { staticClass: "link__text link__sidebar_text" }, [
                  _vm._v("Об'єкти")
                ])
              ]
            ),
            _c(
              "router-link",
              {
                staticClass: "link link__sidebar",
                attrs: {
                  to: "/buyers",
                  "active-class": "link__sidebar--active"
                },
                nativeOn: {
                  click: function($event) {
                    return _vm.hideSidebar($event)
                  }
                }
              },
              [
                _c("i", {
                  staticClass: "ri-shopping-bag-fill link__sidebar_icon"
                }),
                _c("span", { staticClass: "link__text link__sidebar_text" }, [
                  _vm._v("Покупці")
                ])
              ]
            ),
            _c(
              "router-link",
              {
                staticClass: "link link__sidebar",
                attrs: { to: "/rent", "active-class": "link__sidebar--active" },
                nativeOn: {
                  click: function($event) {
                    return _vm.hideSidebar($event)
                  }
                }
              },
              [
                _c("i", { staticClass: "ri-lock-2-fill link__sidebar_icon" }),
                _c("span", { staticClass: "link__text link__sidebar_text" }, [
                  _vm._v("Орендодавці")
                ])
              ]
            ),
            _c(
              "router-link",
              {
                staticClass: "link link__sidebar",
                attrs: {
                  to: "/renters",
                  "active-class": "link__sidebar--active"
                },
                nativeOn: {
                  click: function($event) {
                    return _vm.hideSidebar($event)
                  }
                }
              },
              [
                _c("i", { staticClass: "ri-key-2-line link__sidebar_icon" }),
                _c("span", { staticClass: "link__text link__sidebar_text" }, [
                  _vm._v("Орендарі")
                ])
              ]
            ),
            _vm.$store.getters.user.rank === 4
              ? _c(
                  "router-link",
                  {
                    staticClass: "link link__sidebar",
                    attrs: {
                      to: "/users",
                      "active-class": "link__sidebar--active"
                    },
                    nativeOn: {
                      click: function($event) {
                        return _vm.hideSidebar($event)
                      }
                    }
                  },
                  [
                    _c("i", { staticClass: "ri-user-line link__sidebar_icon" }),
                    _c(
                      "span",
                      { staticClass: "link__text link__sidebar_text" },
                      [_vm._v("Користувачі")]
                    )
                  ]
                )
              : _vm._e(),
            _c(
              "router-link",
              {
                staticClass: "link link__sidebar",
                attrs: {
                  to: "/contacts",
                  "active-class": "link__sidebar--active"
                },
                nativeOn: {
                  click: function($event) {
                    return _vm.hideSidebar($event)
                  }
                }
              },
              [
                _c("i", { staticClass: "ri-contacts-line link__sidebar_icon" }),
                _c("span", { staticClass: "link__text link__sidebar_text" }, [
                  _vm._v("Контакти")
                ])
              ]
            ),
            _c(
              "router-link",
              {
                staticClass: "link link__sidebar",
                attrs: {
                  to: "/seekers",
                  "active-class": "link__sidebar--active"
                },
                nativeOn: {
                  click: function($event) {
                    return _vm.hideSidebar($event)
                  }
                }
              },
              [
                _c("i", {
                  staticClass: "ri-user-search-line link__sidebar_icon"
                }),
                _c("span", { staticClass: "link__text link__sidebar_text" }, [
                  _vm._v("Шукачі")
                ])
              ]
            ),
            _c(
              "router-link",
              {
                staticClass: "link link__sidebar",
                attrs: {
                  to: "/buildings",
                  "active-class": "link__sidebar--active"
                },
                nativeOn: {
                  click: function($event) {
                    return _vm.hideSidebar($event)
                  }
                }
              },
              [
                _c("i", { staticClass: "ri-hotel-line link__sidebar_icon" }),
                _c("span", { staticClass: "link__text link__sidebar_text" }, [
                  _vm._v("Рік будинку")
                ])
              ]
            ),
            _vm.$store.getters.user.rank === 4
              ? _c(
                  "router-link",
                  {
                    staticClass: "link link__sidebar link__sidebar--last",
                    attrs: {
                      to: "/stats",
                      "active-class": "link__sidebar--active"
                    },
                    nativeOn: {
                      click: function($event) {
                        return _vm.hideSidebar($event)
                      }
                    }
                  },
                  [
                    _c("i", {
                      staticClass: "ri-bar-chart-box-line link__sidebar_icon"
                    }),
                    _c(
                      "span",
                      { staticClass: "link__text link__sidebar_text" },
                      [_vm._v("Статистика")]
                    )
                  ]
                )
              : _vm._e(),
            _c("div", { staticClass: "line" }),
            _c(
              "router-link",
              {
                staticClass: "link link__sidebar",
                attrs: {
                  to: "/actions",
                  "active-class": "link__sidebar--active"
                },
                nativeOn: {
                  click: function($event) {
                    return _vm.hideSidebar($event)
                  }
                }
              },
              [
                _c("i", { staticClass: "ri-focus-line link__sidebar_icon" }),
                _c("span", { staticClass: "link__text link__sidebar_text" }, [
                  _vm._v("Дії")
                ])
              ]
            ),
            _c(
              "div",
              {
                staticClass: "link link__sidebar link__sidebar-list",
                class: {
                  "link__sidebar--red":
                    _vm.componentName === "ArchiveObjectsApartments" ||
                    _vm.componentName === "ArchiveBuyersApartments" ||
                    _vm.componentName === "ArchiveRentersApartments" ||
                    _vm.componentName === "ArchiveRentApartments"
                }
              },
              [
                _vm._m(0),
                _c(
                  "router-link",
                  {
                    staticClass: "link__sidebar-bottom",
                    attrs: {
                      to: "/archive/objects",
                      "active-class": "link__sidebar--red"
                    },
                    nativeOn: {
                      click: function($event) {
                        return _vm.hideSidebar($event)
                      }
                    }
                  },
                  [_vm._v("Об'єкти ")]
                ),
                _c(
                  "router-link",
                  {
                    staticClass: "link__sidebar-bottom",
                    attrs: {
                      to: "/archive/buyers",
                      "active-class": "link__sidebar--red"
                    },
                    nativeOn: {
                      click: function($event) {
                        return _vm.hideSidebar($event)
                      }
                    }
                  },
                  [_vm._v("Покупці ")]
                ),
                _c(
                  "router-link",
                  {
                    staticClass: "link__sidebar-bottom",
                    attrs: {
                      to: "/archive/rent",
                      "active-class": "link__sidebar--red"
                    },
                    nativeOn: {
                      click: function($event) {
                        return _vm.hideSidebar($event)
                      }
                    }
                  },
                  [_vm._v("Орендодавці ")]
                ),
                _c(
                  "router-link",
                  {
                    staticClass: "link__sidebar-bottom",
                    attrs: {
                      to: "/archive/renters",
                      "active-class": "link__sidebar--red"
                    },
                    nativeOn: {
                      click: function($event) {
                        return _vm.hideSidebar($event)
                      }
                    }
                  },
                  [_vm._v("Орендарі ")]
                )
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "link link__sidebar link__sidebar-list",
                class: {
                  "link__sidebar--active":
                    _vm.componentName === "FavoriteBuyersApartments" ||
                    _vm.componentName === "FavoriteObjectsApartments" ||
                    _vm.componentName === "FavoriteRentApartments" ||
                    _vm.componentName === "FavoriteRentersApartments"
                }
              },
              [
                _vm._m(1),
                _c(
                  "router-link",
                  {
                    staticClass: "link__sidebar-bottom",
                    attrs: {
                      to: "/favorites/objects",
                      "active-class": "link__sidebar--active"
                    },
                    nativeOn: {
                      click: function($event) {
                        return _vm.hideSidebar($event)
                      }
                    }
                  },
                  [_vm._v("Об'єкти")]
                ),
                _c(
                  "router-link",
                  {
                    staticClass: "link__sidebar-bottom",
                    attrs: {
                      to: "/favorites/buyers",
                      "active-class": "link__sidebar--active"
                    },
                    nativeOn: {
                      click: function($event) {
                        return _vm.hideSidebar($event)
                      }
                    }
                  },
                  [_vm._v("Покупці")]
                ),
                _c(
                  "router-link",
                  {
                    staticClass: "link__sidebar-bottom",
                    attrs: {
                      to: "/favorites/rent",
                      "active-class": "link__sidebar--active"
                    },
                    nativeOn: {
                      click: function($event) {
                        return _vm.hideSidebar($event)
                      }
                    }
                  },
                  [_vm._v("Орендодавці")]
                ),
                _c(
                  "router-link",
                  {
                    staticClass: "link__sidebar-bottom",
                    attrs: {
                      to: "/favorites/renters",
                      "active-class": "link__sidebar--active"
                    },
                    nativeOn: {
                      click: function($event) {
                        return _vm.hideSidebar($event)
                      }
                    }
                  },
                  [_vm._v("Орендарі")]
                )
              ],
              1
            )
          ],
          1
        ),
        _c("div", { staticClass: "sidebar__footer" })
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "link__sidebar-top" }, [
      _c("i", { staticClass: "ri-archive-line link__sidebar_icon" }),
      _c("span", { staticClass: "link__text link__sidebar_text" }, [
        _vm._v("Архів")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "link__sidebar-top" }, [
      _c("i", { staticClass: "ri-save-3-line link__sidebar_icon" }),
      _c("span", { staticClass: "link__text link__sidebar_text" }, [
        _vm._v("Збережені")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }