<template>
  <div class="filters__inner">
    <div class="filters__top">
      <div class="filters__title">
        <span class="filters__title-span">Фільтри</span>
      </div>
      <div class="filters__navigation">
        <div class="button__icon" @click="reset">
          <i class="ri-filter-off-fill"></i>
        </div>
        <div class="button__icon filters__button--blue" @click="send">
          <i class="ri-filter-fill"></i>
          <!-- {{activeParams}} -->
        </div>
      </div>
    </div>
    <div class="filters__main">
      <div class="filters__main-part filters__main-part--active">
        <div v-if="$store.getters.user.role === 4" class="part__main">
          <label class="field__label">
            <div class="field__title">Агент</div>
            <Multiselect
              :options="$store.getters.users"
              v-model="params.agentID"
              label="fullName"
              trackBy="id"
              :multiple="true"
              :showLabels="false"
              :searchable="false"
              placeholder="Агент"
            >
            </Multiselect>
          </label>
        </div>
        <div class="part__main">
          <label class="field__label">
            <div class="field__title">Тип</div>
            <Multiselect
              :options="constants.ACTION_TYPES"
              v-model="params.actionType"
              label="name"
              trackBy="id"
              :multiple="true"
              :showLabels="false"
              :searchable="false"
              placeholder="Тип дії"
            />
          </label>
        </div>
        <div class="part__main">
          <label class="field__label">
            <div class="field__title">Тип продукції</div>
            <Multiselect
              :options="constants.ACTION_PRODUCTION_TYPES"
              v-model="params.productionType"
              label="name"
              trackBy="id"
              :multiple="true"
              :showLabels="false"
              :searchable="false"
              placeholder="Тип продукції"
            />
          </label>
        </div>
        <div class="part__main">
          <label class="field__label">
            <div class="field__title">Результат</div>
            <Multiselect
              :options="constants.ACTION_RESULTS"
              v-model="params.result"
              label="name"
              trackBy="id"
              :multiple="true"
              с
              :showLabels="false"
              :searchable="false"
              placeholder="Результат дії"
            />
          </label>
        </div>
        <div class="part__main">
          <label class="field__label">
            <div class="field__title">Причина відмови</div>
            <Multiselect
              :options="this.rejectionReasons"
              v-model="params.rejectionReason"
              label="name"
              trackBy="id"
              :multiple="true"
              :showLabels="false"
              :searchable="false"
              placeholder="Причина відмови"
            />
          </label>
        </div>
        <div class="part__main">
          <label class="field__label">
            <div class="field__title">Тип об'єкту</div>
            <Multiselect
              :options="constants.ACTION_RELATED_TYPES"
              v-model="params.propertyType"
              label="name"
              trackBy="id"
              :multiple="true"
              :showLabels="false"
              :searchable="false"
              placeholder="Тип об'єкту"
            />
          </label>
        </div>
        <div class="part__main">
          <label class="field__label">
            <div class="field__title">Покупець/орендар</div>
            <Multiselect
              :options="constants.ACTION_RELATED_TYPES"
              v-model="params.buyerType"
              label="name"
              trackBy="id"
              :multiple="true"
              :showLabels="false"
              :searchable="false"
              placeholder="Покупець/орендар"
            />
          </label>
        </div>
        <!-- <div class="part__main">
          <label class="field__label">
            <div class="field__title">ID</div>
            <input class="field__input" type="text" placeholder="ID" v-model="params.relatedID">
          </label>
        </div> -->
        <div class="part__main">
          <div class="field__title">Дата створення</div>
          <date-picker v-model="params.createdAt" is-range isDark color="blue" :popover="{visibility: 'focus'}">
            <template v-slot="{inputValue, inputEvents}">
              <div class="datePicker__fields">
                <input class="field__input" :value="inputValue.start" v-on="inputEvents.start" />
                <input class="field__input" :value="inputValue.end" v-on="inputEvents.end" />
              </div>
            </template>
          </date-picker>
        </div>
        <div class="part__main">
          <div class="field__title">Дата дії</div>
          <date-picker v-model="params.actionAt" is-range isDark color="blue" :popover="{visibility: 'focus'}">
            <template v-slot="{inputValue, inputEvents}">
              <div class="datePicker__fields">
                <input class="field__input" :value="inputValue.start" v-on="inputEvents.start" />
                <input class="field__input" :value="inputValue.end" v-on="inputEvents.end" />
              </div>
            </template>
          </date-picker>
        </div>
        <div class="part__main part__main--half">
          <label class="field__label">
            <div class="field__title">Кількість продукції</div>
            <input type="text" placeholder="Від" class="field__input" v-mask="'####'" v-model="params.productionNumber.from" />
          </label>
          <label class="field__label">
            <input type="text" placeholder="До" class="field__input" v-mask="'####'" v-model="params.productionNumber.till" />
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import Multiselect from 'vue-multiselect'
import _ from 'lodash'

export default {
  name: 'FilterActions',
  components: {
    DatePicker,
    Multiselect
  },
  data: () => ({
    params: Object,
    constants: Object,
    activeParams: Number
  }),
  watch: {
    params: {
      deep: true,
      handler(p) {
        let aParam = 0
        for (const par in p) {
          if (p[par].length) {
            aParam++
          }
        }
        if (p.createdAt?.start) {
          aParam++
        }
        this.activeParams = aParam
      }
    }
  },
  methods: {
    async send() {
      const p = this.params
      const params = {
        'filter[agentID]': p.agentID ? p.agentID.map(type => type.id) : null,
        'filter[actionType]': p.actionType ? p.actionType.map(type => type.id) : null,
        'filter[productionType]': p.productionType ? p.productionType.map(type => type.id) : null,
        'filter[result]': p.result ? p.result.map(item => item.id) : null,
        'filter[rejectionReason]': p.rejectionReason ? p.rejectionReason.map(item => item.id) : null,
        'filter[propertyType]': p.propertyType ? p.propertyType.map(item => item.id) : null,
        'filter[buyerType]': p.buyerType ? p.buyerType.map(item => item.id) : null,
        // 'filter[relatedID]': p.relatedID ? p.relatedID : null,
        'filter[createdAt][from]': p.createdAt.start ? p.createdAt.start.format() : null,
        'filter[createdAt][till]': p.createdAt.end ? p.createdAt.end.format() : null,
        'filter[actionAt][from]': p.actionAt.start ? p.actionAt.start.format() : null,
        'filter[actionAt][till]': p.actionAt.end ? p.actionAt.end.format() : null,
        'filter[productionNumber][from]': p.productionNumber?.from || null,
        'filter[productionNumber][till]': p.productionNumber?.till || null,
      }
      this.$store.commit('filterParamsActions', params)
      this.$store.commit('filterParamsActionsAll', p)
      this.$store.commit('filterActiveParams', this.activeParams)

      this.$store.commit('resetActions')
      await this.$store.dispatch('fetchActions')
      this.$modal.hide('FilterActions')
    },
    reset() {
      this.params = _.cloneDeep(this.$store.getters.filterParamsActionsOriginal)
    }
  },
  created() {
    this.constants = this.$store.getters.constants
    if (!_.isEmpty(this.$store.getters.filterParamsActionsAll)) {
      this.params = _.cloneDeep(this.$store.getters.filterParamsActionsAll)
    } else {
      this.params = _.cloneDeep(this.$store.getters.filterParamsActionsOriginal)
    }
    if (!this.$store.getters.users.length && this.$store.getters.user.role === 4) {
      this.$store.dispatch('fetchUsers')
    }
    this.rejectionReasons = [...this.constants.ACTION_SHOWING_REJECTION_REASONS, ...this.constants.ACTION_VIEWING_REJECTION_REASONS]
  }
}
</script>
<style lang="scss" scoped>
@import './style.scss';
</style>
