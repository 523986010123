<template>
  <div v-if="type === 'info-row'" class="rel__block" :class="{ 'rel__block--golden': relation.isGolden }" @click="openRelation">
    <div class="rel__circle" :class="{'rel__circle--red': isArchived}"></div>
    <div v-if="relation.contactableType === 'properties'" class="rel__text">Об'єкт / </div>
    <div v-if="relation.contactableType === 'buyers'" class="rel__text">Покупець / </div>
    <div v-if="relation.contactableType === 'seekers'" class="rel__text">Шукач / </div>
    <div v-if="relation.contactableType === 'buildings'" class="rel__text">Рік Будинку / </div>
    <div v-if="relation.contactableSubtype === 'apartment'" class="rel__text">Квартира / </div>
    <div v-if="relation.contactableSubtype === 'house'" class="rel__text">Будинок / </div>
    <div v-if="relation.contactableSubtype === 'commerce'" class="rel__text">Комерція / </div>
    <div class="rel__text">ID {{ relation.contactableId }} / </div>
    <div class="rel__text">{{ relation.createdAt | date }}</div>
    <div v-if="relation.isSoldByOurAgency" class="rel__text"> / Наше АН</div>
  </div>
  <div v-else-if="type === 'info-tile'" class="tile__item main__relationId" @click="openRelation">
    <i v-if="relation.contactableType === 'buyers'" class="ri-user-line"></i>
    <i
      v-if="relation.contactableType === 'properties'"
      :class="{
        'ri-building-4-line': relation.contactableSubtype === 'apartment',
        'ri-home-5-line': relation.contactableSubtype === 'house',
        'ri-store-line': relation.contactableSubtype === 'commerce',
      }"
    ></i>
    <span style="margin-left: 6px">{{ relation.contactableId }}</span>
  </div>
  <div v-else-if="type === 'card-tile'" class="card__rel" @click.stop.prevent="openRelation">
    ID {{ relation.contactableId }} <i class="i-relation"></i>
  </div>
  <div v-else-if="type === 'notification-icon'" class="notification-icon" @click.stop.prevent="openRelation">
    <i v-if="relation.contactableType === 'buyers'" class="ri-user-line"></i>
    <i
      v-if="relation.contactableType === 'properties'"
      :class="{
        'ri-building-4-line': relation.contactableSubtype === 'apartment',
        'ri-home-5-line': relation.contactableSubtype === 'house',
        'ri-store-line': relation.contactableSubtype === 'commerce',
      }"
    ></i>
    <span style="margin-left: 6px">{{ relation.contactableId }}</span>
  </div>
</template>

<script>
export default {
  name: 'Relation',
  props: {
    relation: {},
    type: {
      default: 'info-row' // 'info-row' | 'info-tile' | 'card-tile' | 'notification-icon'
    },
  },
  data: () => ({
    constants: null
  }),
  beforeCreate() {
    this.InfoBuyer = require('@/views/Buyers/Info').default                   // Using of import InfoBuyer or InfoObject throws the error
    this.InfoObject = require('@/views/Objects/Info').default                 // "Unknown custom element: <Relation> - did you register the component correctly"
    this.InfoObjectArchived = require('@/views/Archive/Objects/Info').default // when InfoBuyer or InfoObject is open
    this.InfoSeeker = require('@/views/Seekers/Info').default
    this.InfoBuilding = require('@/views/Buildings/Info').default
  },
  computed: {
    isProperty() {
      return this.relation.contactableType === 'properties'
    },
    isBuyer() {
      return this.relation.contactableType === 'buyers'
    },
    isSeeker() {
      return this.relation.contactableType === 'seekers'
    },
    isBuilding() {
      return this.relation.contactableType === 'buildings'
    },
    isApartment() {
      return this.relation.contactableSubtype === 'apartment'
    },
    isHouse() {
      return this.relation.contactableSubtype === 'house'
    },
    isCommerce() {
      return this.relation.contactableSubtype === 'commerce'
    },
    isArchived() {
      return this.relation.isArchived
    }
  },
  methods: {
    async openRelation() {
      const path = this.getPath()

      await this.$store.dispatch(path.fetch, this.relation.contactableId)
      const data = this.$store.getters[path.get]

      if (this.isBuyer) {
        this.$modal.display(this.InfoBuyer, {buyer: data}, { ...this.$modal.styles, name: 'InfoBuyer'})
      } else if (this.isProperty && !this.isArchived) {
        this.$modal.display(this.InfoObject, {info: data}, { ...this.$modal.styles, name: 'InfoObject'})
      } else if (this.isProperty && this.isArchived) {
        this.$modal.show(this.InfoObjectArchived, {info: data}, { ...this.$modal.styles, name: 'InfoObject'})
      } else if (this.isSeeker) {
        this.$modal.display(this.InfoSeeker, {seeker: data}, { ...this.$modal.styles, name: 'InfoSeeker'})
      } else if (this.isBuilding) {
        this.$modal.display(this.InfoBuilding, {building: data}, { ...this.$modal.styles, name: 'InfoBuilding'})
      }
    },
    getPath() {
      if (this.isSeeker) {
        return { fetch: 'seekers/fetchSeeker', get: 'seekers/seeker'}
      }
      if (this.isBuilding) {
        return { fetch: 'buildings/fetchBuilding', get: 'buildings/building'}
      }

      let path
      if (this.isProperty) {
        if (this.isApartment) {
          path = { fetch: 'objects/apartments/fetchApartment', get: 'objects/apartments/apartment'}
        } else if (this.isHouse) {
          path = { fetch: 'objects/houses/fetchHouse', get: 'objects/houses/house'}
        } else if (this.isCommerce) {
          path = { fetch: 'objects/commerce/fetchCommerce', get: 'objects/commerce/oneCommerce'}
        }
      } else if (this.isBuyer) {
        if (this.isApartment) {
          path = { fetch: 'buyers/apartments/fetchApartment', get: 'buyers/apartments/apartment'}
        } else if (this.isHouse) {
          path = { fetch: 'buyers/houses/fetchHouse', get: 'buyers/houses/house'}
        } else if (this.isCommerce) {
          path = { fetch: 'buyers/commerce/fetchCommerce', get: 'buyers/commerce/oneCommerce'}
        }
      }

      if (this.isArchived) {
        return { fetch: 'archive/' + path.fetch, get: 'archive/' + path.get }
      }

      return path
    }
  }
}
</script>
<style lang="scss" scoped>
@import 'styles';
</style>
