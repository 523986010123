<template>
  <div class="sidebar__wrapper">
    <div class="sidebar">
      <div class="sidebar__inner">
        <div class="sidebar__header"></div>
        <div class="sidebar__main">
          <router-link
            to="/objects/"
            class="link link__sidebar"
            active-class="link__sidebar--active"
            @click.native="hideSidebar"
          >
            <i class="ri-database-line link__sidebar_icon"></i>
            <span class="link__text link__sidebar_text">Об'єкти</span>
          </router-link>
          <router-link
            to="/buyers"
            class="link link__sidebar"
            active-class="link__sidebar--active"
            @click.native="hideSidebar"
          >
            <i class="ri-shopping-bag-fill link__sidebar_icon"></i>
            <span class="link__text link__sidebar_text">Покупці</span>
          </router-link>
          <router-link
            to="/rent"
            class="link link__sidebar"
            active-class="link__sidebar--active"
            @click.native="hideSidebar"
          >
            <i class="ri-lock-2-fill link__sidebar_icon"></i>
            <span class="link__text link__sidebar_text">Орендодавці</span>
          </router-link>
          <router-link
            to="/renters"
            class="link link__sidebar"
            active-class="link__sidebar--active"
            @click.native="hideSidebar"
          >
            <i class="ri-key-2-line link__sidebar_icon"></i>
            <span class="link__text link__sidebar_text">Орендарі</span>
          </router-link>
          <router-link
            v-if="$store.getters.user.rank === 4"
            to="/users"
            class="link link__sidebar"
            active-class="link__sidebar--active"
            @click.native="hideSidebar"
          >
            <i class="ri-user-line link__sidebar_icon"></i>
            <span class="link__text link__sidebar_text">Користувачі</span>
          </router-link>
          <router-link
            to="/contacts"
            class="link link__sidebar"
            active-class="link__sidebar--active"
            @click.native="hideSidebar"
          >
            <i class="ri-contacts-line link__sidebar_icon"></i>
            <span class="link__text link__sidebar_text">Контакти</span>
          </router-link>
          <router-link
            to="/seekers"
            class="link link__sidebar"
            active-class="link__sidebar--active"
            @click.native="hideSidebar"
          >
            <i class="ri-user-search-line link__sidebar_icon"></i>
            <span class="link__text link__sidebar_text">Шукачі</span>
          </router-link>
          <router-link
            to="/buildings"
            class="link link__sidebar"
            active-class="link__sidebar--active"
            @click.native="hideSidebar"
          >
            <i class="ri-hotel-line link__sidebar_icon"></i>
            <span class="link__text link__sidebar_text">Рік будинку</span>
          </router-link>
          <router-link
            v-if="$store.getters.user.rank === 4"
            to="/stats"
            class="link link__sidebar link__sidebar--last"
            active-class="link__sidebar--active"
            @click.native="hideSidebar"
          >
            <i class="ri-bar-chart-box-line link__sidebar_icon"></i>
            <span class="link__text link__sidebar_text">Статистика</span>
          </router-link>
          <div class="line"></div>
          <router-link
            to="/actions"
            class="link link__sidebar"
            active-class="link__sidebar--active"
            @click.native="hideSidebar"
          >
            <i class="ri-focus-line link__sidebar_icon"></i>
            <span class="link__text link__sidebar_text">Дії</span>
          </router-link>

          <div
            class="link link__sidebar link__sidebar-list"
            :class="{
              'link__sidebar--red':
                componentName === 'ArchiveObjectsApartments'
                || componentName === 'ArchiveBuyersApartments'
                || componentName === 'ArchiveRentersApartments'
                || componentName === 'ArchiveRentApartments'
            }"
          >
            <div class="link__sidebar-top">
              <i class="ri-archive-line link__sidebar_icon"></i>
              <span class="link__text link__sidebar_text">Архів</span>
            </div>
            <router-link
              to="/archive/objects"
              class="link__sidebar-bottom"
              active-class="link__sidebar--red"
              @click.native="hideSidebar"
              >Об'єкти
            </router-link>
            <router-link
              to="/archive/buyers"
              class="link__sidebar-bottom"
              active-class="link__sidebar--red"
              @click.native="hideSidebar"
              >Покупці
            </router-link>
            <router-link
              to="/archive/rent"
              class="link__sidebar-bottom"
              active-class="link__sidebar--red"
              @click.native="hideSidebar"
              >Орендодавці
            </router-link>
            <router-link
              to="/archive/renters"
              class="link__sidebar-bottom"
              active-class="link__sidebar--red"
              @click.native="hideSidebar"
              >Орендарі
            </router-link>
          </div>

          <div
            class="link link__sidebar link__sidebar-list"
            :class="{
              'link__sidebar--active':
                componentName === 'FavoriteBuyersApartments' ||
                componentName === 'FavoriteObjectsApartments' ||
                componentName === 'FavoriteRentApartments' ||
                componentName === 'FavoriteRentersApartments'
            }"
          >
            <div class="link__sidebar-top">
              <i class="ri-save-3-line link__sidebar_icon"></i>
              <span class="link__text link__sidebar_text">Збережені</span>
            </div>
            <router-link
              to="/favorites/objects"
              class="link__sidebar-bottom"
              active-class="link__sidebar--active"
              @click.native="hideSidebar"
              >Об'єкти</router-link
            >
            <router-link
              to="/favorites/buyers"
              class="link__sidebar-bottom"
              active-class="link__sidebar--active"
              @click.native="hideSidebar"
              >Покупці</router-link
            >
            <router-link
              to="/favorites/rent"
              class="link__sidebar-bottom"
              active-class="link__sidebar--active"
              @click.native="hideSidebar"
              >Орендодавці</router-link
            >
            <router-link
              to="/favorites/renters"
              class="link__sidebar-bottom"
              active-class="link__sidebar--active"
              @click.native="hideSidebar"
              >Орендарі</router-link
            >
          </div>
        </div>
        <div class="sidebar__footer"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Sidebar',
  computed: {
    componentName() {
      return this.$route.name
    }
  },
  methods: {
    hideSidebar() {
      this.$modal.hide('Sidebar')
    }
  }
}
</script>

<style lang="scss" scoped>
@import './style.scss';
</style>
